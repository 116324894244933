import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
const ToastPopup = ({ message, onClose, type = 'success' }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div style={{ 
      position: 'fixed', 
      top: '20px', 
      right: '20px', 
      zIndex: 1050 
    }}>
      <Toast 
        onClose={() => setShow(false)} 
        show={show} 
        delay={3000} 
        autohide
        bg={type === 'success' ? 'success' : 'danger'}
      >
        <Toast.Header>
          <strong className="me-auto">
            {type === 'success' ? 'Success' : 'Error'}
          </strong>
        </Toast.Header>
        <Toast.Body className="text-white">{message}</Toast.Body>
      </Toast>
    </div>
  );
};

export default ToastPopup;