import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import ContactSectionImage from "../assets/images/contact-draw.png";
import FormInput from "../components/FormInput";
import FormTextarea from "../components/FormTextarea";
import ToastPopup from "../components/ToastPopup";

const Contact = ({ setActivePage }) => {
  const { t } = useTranslation();

  const [isSend, setIsSend] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [isValidMsg, setIsValidMsg] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");

  useEffect(() => {
    setActivePage("contact");
  }, [setActivePage]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsSend(true);

    try {
      const result = await emailjs.send(
        "service_v8bp5ri",
        "template_56zypbi",
        formData,
        "VPHXCprBTqU0ynXqP"
      );

      if (result.text === "OK") {
        setToastMessage(t("contact.successMsg"));
        setToastType("success");
        setShowToast(true);
        
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      } else {
        setToastMessage(t("contact.errorMsg"));
        setToastType("error");
        setShowToast(true);
      }
    } catch (error) {
      console.error("EmailJS error: ", error);
      setToastMessage(t("contact.errorMsg"));
      setToastType("error");
      setShowToast(true);
    } finally {
      setIsSend(false);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  return (
    <section id="contact">
      {/* Show toast popup if showToast is true */}
      {showToast && (
        <ToastPopup
          message={toastMessage} 
          type={toastType}
          onClose={() => setShowToast(false)} 
        />
      )}
      <div className="section-container">
        <div className="contact-form-container">
          <p className="form-msg">{t("contact.section-descrip")}</p>

          <div
            id="form-message"
            className={"alert alert-primary ".isValidMsg ? "successMessage" : "errorMessage"}
          >
            {isValidMsg}
          </div>

          <form className="contact-form" onSubmit={handleSubmitForm}>
            <input
              type="hidden"
              className="input is-hidden"
              name="lang"
              value="en"
              id="lang"
            />

            <div className="field">
              <div className="control">
                <input type="hidden" className="input is-hidden" name="empty" />
              </div>
            </div>

            <div className="field">
              <div className="field-body columns">
                <FormInput
                  name="name"
                  type="text"
                  label={t("contact.form.name")}
                  handleOnChange={handleOnChange}
                  errorMsg={t("contact.errors.general")}
                  inputPattern="^[a-zA-Z ]+$"
                  isRequired={true}
                  classes="column is-6 field mr-0"
                />

                <FormInput
                  name="email"
                  type="email"
                  label={t("contact.form.email")}
                  handleOnChange={handleOnChange}
                  errorMsg={t("contact.errors.email")}
                  inputPattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                  isRequired={true}
                  classes="column is-6 field"
                />
              </div>
            </div>

            <div className="field">
              <div className="field-body columns">
                <FormInput
                  name="phone"
                  type="text"
                  label={t("contact.form.phone")}
                  handleOnChange={handleOnChange}
                  errorMsg={t("contact.errors.phone")}
                  inputPattern="^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"
                  isRequired={false}
                  classes="column is-6 field mr-0"
                />

                <FormInput
                  name="subject"
                  type="text"
                  label={t("contact.form.subject")}
                  handleOnChange={handleOnChange}
                  errorMsg={t("contact.errors.general")}
                  inputPattern="^[a-zA-Z0-9! ]+$"
                  isRequired={true}
                  classes="column is-6 field"
                />
              </div>
            </div>

            <div className="field">
              <FormTextarea
                name="message"
                label={t("contact.form.message")}
                handleOnChange={handleOnChange}
                isRequired={true}
                message={formData.message}
                setIsValidMsg={setIsValidMsg}
              />
            </div>

            <div className="field">
              <button
                id="submit-btn"
                className="button is-rounded"
                type="submit"
              >
                {isSend ? t("contact.sending-btn") : t("contact.send-btn")}
              </button>
            </div>
          </form>
        </div>

        <div className="section-title contact-title">
          <h2 className="is-uppercase title">{t("contact.heading")}</h2>
        </div>

        <figure className="contact-draw-image image">
          <img
            src={ContactSectionImage}
            alt="reviews about Jhone Doe Services"
          />
        </figure>
      </div>
    </section>
  );
};

export default Contact;
